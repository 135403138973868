import { useMemoizedContentGetter } from 'utils/contentful'
import Section from 'components/Section'
import styles from './ArticlesCarousel.module.scss'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { AchieveTheme, Grid } from '@achieve/ascend'
import { Typography, MediaImageStandard } from 'components/Contentful'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { TitleDivider } from 'components/TitleDivider'
import { Carousel } from 'components/Carousels'
import { getSlug } from 'utils/conversions'
import { CONTENT_TYPES } from 'services/contentful/constants'

function ArticleCard({ item: { title, subTitle, link, category, categoryLink, image }, index }) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const isXS = useViewportSmallerThan(BREAKPOINTS.md)
  const CardContent = (
    <AchieveLink
      href={link}
      track={{
        list_name: `ARTICLE LINK`,
        position: index,
        click_id: `Achieve Web | ${title}`,
        click_text: title,
        click_url: link,
      }}
      className={styles['card-link']}
      underline="hover"
    >
      <div>
        {image && (
          <div className={styles['img-container']}>
            <span>
              <MediaImageStandard
                content={image}
                height={isXS ? 204 : 215}
                width={isXS ? 315 : 384}
              />
            </span>
          </div>
        )}

        {!isMobile && category && categoryLink && (
          <Typography
            variant="bodySm"
            fontWeight="bold"
            className={styles['card-category']}
            data-testid="card-category"
            content={category}
          />
        )}
        {!isMobile && category && !categoryLink && (
          <Typography
            content={category}
            variant="bodySm"
            fontWeight="bold"
            component="h3"
            className={styles['card-category']}
            data-testid="card-category"
          />
        )}
        {title && (
          <Typography
            content={title}
            variant={isMobile ? 'bodyLg' : 'headingMd'}
            component="h3"
            className={styles['card-title']}
            fontWeight="bold"
            data-testid="card-title"
          />
        )}
        {subTitle && (
          <Typography
            content={subTitle}
            variant={isMobile ? 'bodySm' : 'bodyLg'}
            className={styles['card-sub-title']}
            fontWeight="regular"
            data-testid="card-text"
          />
        )}
      </div>
    </AchieveLink>
  )

  return <Grid className={styles['card-container']}>{CardContent}</Grid>
}

const ArticlesCarousel = ({ content }) => {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const isSmallerXL = useViewportSmallerThan(BREAKPOINTS.xl)
  const sectionIdPrefix = `press-carousel-more-resources-carousel`
  const { title, link } = useMemoizedContentGetter(content, ['title', 'link'])
  const additionalConfiguration = content?.fields?.additionalConfiguration?.jsonContent
  const itemList = content?.fields?.relatedContentCollection?.items
    ?.filter((e) => {
      return ![
        CONTENT_TYPES.ARTICLE_FULL,
        CONTENT_TYPES.CORPORATE_BLOG_FULL,
        CONTENT_TYPES.CONSUMER_BLOG_FULL,
        CONTENT_TYPES.EXTERNAL_LINK_FUll,
      ].includes(e?.__typename)
        ? false
        : true
    })
    ?.map((item) => {
      if (item?.__typename === CONTENT_TYPES.EXTERNAL_LINK_FUll) {
        return {
          sys: item?.sys,
          title: item?.title,
          subTitle: item?.subTitle,
          link: item?.link,
          category: item?.category,
          categoryLink: item?.categoryLink,
          image: item?.image?.mediaContent,
        }
      } else {
        let shortSubTitle = item?.hook
        const articleUrl =
          item?.__typename == 'corporateBlog' || item?.__typename == 'CorporateBlog'
            ? 'about/blog'
            : item?.__typename == 'consumerBlog' || item?.__typename == 'ConsumerBlog'
            ? 'dollars-sense'
            : 'learn'
        return {
          sys: item?.sys,
          title: item?.title,
          subTitle: shortSubTitle,
          link: `/${articleUrl}/${getSlug(item?.primaryCategory)}/${item?.slug}`,
          category: item?.primaryCategory,
          categoryLink: `/${articleUrl}/${getSlug(item?.primaryCategory)}`,
          image: item?.mainImage?.mediaContent,
        }
      }
    })
  const variant = {
    title: isMobile ? 'headingMd' : 'displayLg',
    titleFontWeight: 'medium',
    subtitle: isMobile ? 'bodySm' : 'displaySm',
    subtitleFontWeight: 'regular',
  }

  const carouselEnabledByItemCount = itemList?.length > 3

  return (
    <>
      {itemList?.length > 0 && (
        <Section
          contain={false}
          className={styles['section']}
          backgroundColor={
            additionalConfiguration?.backgroundColor === 'blue'
              ? AchieveTheme?.ascend?.colors?.blue?.[50]
              : null
          }
        >
          <TitleDivider
            className={styles['title-divider']}
            title={title}
            isMobile={isMobile}
            data-testid={`featured-press-carousel-title`}
            variants={variant}
          />
          <Carousel
            carouselItems={itemList}
            className={styles['articles-carousel-wrapper']}
            classNameContainer={styles['articles-carousel-container']}
            classNameItems={styles['articles-carousel-items']}
            itemComponent={ArticleCard}
            variant="auto"
            idPrefix={sectionIdPrefix}
            disableArrows={isSmallerXL || carouselEnabledByItemCount ? false : true}
            withIndicators={false}
            classNameControls={styles['carousel-wrapper-controls']}
          />
          {link && (
            <AchieveLink
              href={link.linkHref}
              track={{
                ...{
                  list_name: `ARTICLE LINK`,
                  click_id: link?.linkText?.json?.content[0]?.content[0]?.value,
                  click_text: link?.linkText?.json.content[0]?.content[0]?.value,
                },
                ...link?.event,
              }}
              fontWeight="bold"
              variant="bodySm"
              className={styles['link']}
              underline="hover"
            >
              {link?.linkText?.json?.content?.[0]?.content[0]?.value}
            </AchieveLink>
          )}
        </Section>
      )}
    </>
  )
}
export { ArticlesCarousel }
export default ArticlesCarousel
